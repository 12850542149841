<template>
  <div class="main">
    <Slider v-if="false"></Slider>
    <div class="search-pass" v-if="searchShow">
      <div class="search-main">
        <div class="search-main-div">
          <input type="password" v-model="searchPass" placeholder="请输入查看密码">
          <div class="search-main-sel" ><el-checkbox v-model="sessionPass">记住密码</el-checkbox></div>
          <div class="btn-s" @click="loolSearch">查看数据</div>
        </div>
      </div>
    </div>
    <div class="" v-if="!searchShow">
      <div class="search">
        <div class="select">
          <!--形状-->
          <div class="items shape">
            <div class="item title">形状</div>
            <div class="item" :class="item.sel ? 'sel' : ''" v-for="(item, index) in shape" :key="index"
                 @click="selItem('shape', item.val)">
              <img v-show="!item.sel" :src="item.img" alt="">
              <img v-show="item.sel" :src="item.selimg" alt="">
              <div class="text">{{item.show}}</div>
            </div>
          </div>
          <div class="items">
            <div class="item title">颜色</div>
            <div class="item animated zoomIn" :class="item.sel ? 'sel' : ''" v-for="(item, index) in color" :key="index"
                 @click="selItem('color', item.val)">{{item.val}}
            </div>
          </div>
          <div class="items">
            <div class="item title">净度</div>
            <div class="item" :class="item.sel ? 'sel' : ''" v-for="(item, index) in clarity" :key="index"
                 @click="selItem('clarity', item.val)">{{item.val}}
            </div>
          </div>
          <div class="items">
            <div class="item title">快捷</div>
            <div class="item" :class="item.sel ? 'sel' : ''" v-for="(item, index) in hotkey" :key="index"
                 @click="hotCheck('hotkey', item.val)">{{item.val}}
            </div>
          </div>
          <div class="items" v-show="cutShow">
            <div class="item title">切工</div>
            <div class="item" :class="item.sel ? 'sel' : ''" v-for="(item, index) in cut" :key="index"
                 @click="selItem('cut', item.val)">{{item.val}}
            </div>
          </div>
          <div class="items">
            <div class="item title">抛光</div>
            <div class="item" :class="item.sel ? 'sel' : ''" v-for="(item, index) in polish" :key="index"
                 @click="selItem('polish', item.val)">{{item.val}}
            </div>
          </div>
          <div class="items">
            <div class="item title">对称</div>
            <div class="item" :class="item.sel ? 'sel' : ''" v-for="(item, index) in sym" :key="index"
                 @click="selItem('sym', item.val)">{{item.val}}
            </div>
          </div>
          <div class="items">
            <div class="item title">荧光</div>
            <div class="item" :class="item.sel ? 'sel' : ''" v-for="(item, index) in flour" :key="index"
                 @click="selItem('flour', item.val)">{{item.val}}
            </div>
          </div>
          <div class="items">
            <div class="item title">证书</div>
            <div class="item" :class="item.sel ? 'sel' : ''" v-for="(item, index) in cert" :key="index"
                 @click="selItem('cert', item.val)">{{item.val}}
            </div>
          </div>
          <!-- <div class="items" style="margin-bottom:10px;">
            <div class="item title">产地</div>
            <div class="item" style="margin-right:20px;padding: 20px 40px;" :class="item.sel ? 'sell' : ''" v-for="(item, index) in productionPlace" :key="index"
                 @click="selItem('Place', item.val)">{{item.val}}
            </div>
          </div> -->
        </div>
        <!--      右手选项-->
        <div class="check">
          <!--        重量-->
          <div class="items">
            <div class="input-main">
              <input type="text" ref="diaSizeMin" @input="changectMin($event)" :value="diaSizeMin">
              <div class="">-</div>
              <input type="text" ref="diaSizeMax" @input="changectMax($event)" :value="diaSizeMax">
            </div>
            <div class="i-text">重量</div>
            <el-select v-model="diaval" :popper-append-to-body="false" placeholder="请选择">
              <el-option :value="item" v-for="(item, index) in diaSize" :key="index"></el-option>
            </el-select>
          </div>
          <!--        价格范围-->
          <!--        <div class="items">-->
          <!--          <div class="input-main">-->
          <!--            <input type="text" ref="diaPriceMin" @input="changeMin" :value="diaPriceMin">-->
          <!--            <div class="">-</div>-->
          <!--            <input type="text" ref="diaPriceMax" @input="changeMax" :value="diaPriceMax">-->
          <!--          </div>-->
          <!--          <div class="i-text">价格</div>-->
          <!--        </div>-->
          <!--        gia证书号-->
          <div class="items GIA">
            <div class="input-main">
              <input type="text" ref="certNo" @input="changGIA($event)" :value="certNo" placeholder="请输入证书号">
            </div>
            <el-select value="证书号" placeholder="" :popper-append-to-body="false">
              <el-option label="证书号" value="证书号"></el-option>
            </el-select>

          </div>
          <!--        肉眼净，奶咖，图/视频-->
          <div class="items">
            <div class="label" :class="item.sel ? 'sel' : ''" v-for="(item, index) in radioItem" :key="index"
                 @click="selItem('radioItem', item.val)">
              <div class="checked"></div>
              <div class="check-text">{{item.text}}</div>
            </div>
          </div>
          <!--        货所在地-->
          <div class="items">
            <div class="label" :class="item.sel ? 'sel' : ''" v-for="(item, index) in locations" :key="index"
                 @click="selItem('locations', item.val)">
              <div class="checked"></div>
              <div class="check-text">{{item.text}}</div>
            </div>
          </div>

          <div class="items">
            <div class="search-btn" @click="getSear">搜索</div>
            <div class="search-btn" @click="clearSearch">重置</div>
             <!-- VIP钻石小鸟高端定制   18888921203 -->
            <div class="search-btn" @click="shortcutSearch">一键选择</div>
          </div>
        </div>
      </div>
      <!--    合计、分页-->
      <div id="searchId"></div>
      <div class="total-main">
        <div class="total">共{{total}}颗钻石</div>
        <div class="page">
          <el-pagination background layout="sizes, prev, pager, next" @size-change="handleSizeChange" :current-page="currentPage" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize"
                         :total="total" @current-change="changePage"></el-pagination>
        </div>
      </div>
      <!--    表格-->
      <el-table
        :data="tableData"
        style="width: 100%"
        ref="tableList"
        :row-class-name="listsle"
        v-loading="listloading"
        @row-click="goDetails"
        :default-sort = "{prop: 'price', order: 'ascending'}"
        @sort-change="sortChange">
        <!--      <el-table-column type="selection" width="50"></el-table-column>-->
        <el-table-column width="70" label="图片">
          <template slot-scope="scope">
            <div class="list-img" v-show="scope.row.image != ''">
              <img :src="scope.row.image" alt="">
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="cert" width="60" label="证书"></el-table-column>
        <el-table-column prop="measurement" width="140" label="尺寸">
          <template slot-scope="scope">
            <div>{{scope.row.measurement == '' || scope.row.measurement == '-' ? '待查' : scope.row.measurement}}</div>
          </template>
        </el-table-column>
        <!--      <el-table-column width="140" label="证书号" v-if="GIAShow">-->
        <!--        <template slot-scope="scope">-->
        <!--          <div class="" v-show="scope.row.certNo != ''">{{scope.row.certNo}}</div>-->
        <!--        </template>-->
        <!--      </el-table-column>-->
        <el-table-column prop="shape" width="65" label="形状"></el-table-column>
        <!--      <el-table-column prop="diaSize" sortable label="重量"></el-table-column>-->
        <el-table-column prop="diaSize" width="80" label="重量" sortable :sort-orders="['ascending','descending']"></el-table-column>
        <el-table-column prop="color" width="55" label="颜色"></el-table-column>
        <el-table-column prop="clarity" width="60" label="净度"></el-table-column>
        <el-table-column prop="cut" width="55" label="切工">
          <template slot-scope="scope">
            <div class="">{{scope.row.cut || '-'}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="polish" width="55" label="抛光">
          <template slot-scope="scope">
            <div class="">{{scope.row.polish || '-'}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="sym" width="55" label="对称">
          <template slot-scope="scope">
            <div class="">{{scope.row.sym || '-'}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="flour" width="55" label="荧光">
          <template slot-scope="scope">
            <div class="">{{scope.row.flour || '-'}}</div>
          </template>
        </el-table-column>
        <!--      <el-table-column prop="price" width="100" sortable label="价格">-->

        <el-table-column prop="browness" width="63" label="咖色"></el-table-column>
        <el-table-column prop="milky" width="63" label="奶色"></el-table-column>
        <el-table-column prop="location" width="63" label="所在地">
          <template slot-scope="scope">
            <div>{{scope.row.location | getComeTime}}</div>
          </template>
        </el-table-column>
        <!-- ="custom" -->
        <el-table-column prop="price" label="价格" sortable :sort-orders="['ascending','descending']">
          <template slot-scope="scope">
            <div class="list-price">￥{{scope.row.price}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="updateTime" width="150" label="更新时间"></el-table-column>

      </el-table>

      <!--    分页-->
      <div class="page last">
        <el-pagination background layout="sizes, prev, pager, next" @size-change="handleSizeChanges" :current-page="currentPage" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize"
                       :total="total" @current-change="changePages"></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
  import Diamond from '@/api/diamond'
  import Slider from '@/components/slider/slider'

  export default {
    name: 'diamondSearch',
    components:{
      Slider
    },
    data () {
      return {
        // 形状
        shape: [],
        // 颜色
        color: [
          {
            val: 'D',
            sel: false
          },
          {
            val: 'E',
            sel: false
          },
          {
            val: 'F',
            sel: false
          },
          {
            val: 'G',
            sel: false
          },
          {
            val: 'H',
            sel: false
          },
          {
            val: 'I',
            sel: false
          },
          {
            val: 'J',
            sel: false
          },
          {
            val: 'K',
            sel: false
          },
          {
            val: 'L',
            sel: false
          },
          {
            val: 'M',
            sel: false
          }
        ],
        // 净度
        clarity: [
          {
            val: 'FL',
            sel: false
          },
          {
            val: 'IF',
            sel: false
          },
          {
            val: 'VVS1',
            sel: false
          },
          {
            val: 'VVS2',
            sel: false
          },
          {
            val: 'VS1',
            sel: false
          },
          {
            val: 'VS2',
            sel: false
          },
          {
            val: 'SI1',
            sel: false
          },
          {
            val: 'SI2',
            sel: false
          },
          {
            val: 'I1',
            sel: false
          },
          {
            val: 'I2',
            sel: false
          }
        ],
        // 切工
        cut: [
          {
            val: 'EX',
            sel: false
          },
          {
            val: 'VG',
            sel: false
          },
          {
            val: 'GD',
            sel: false
          },
          {
            val: 'FR',
            sel: false
          },
          {
            val: 'P',
            sel: false
          }
        ],
        // 快捷键
        hotkey: [
          {
            val: '3EX',
            sel: false
          },
          {
            val: '3VG',
            sel: false
          },
          {
            val: '3GD',
            sel: false
          },
          {
            val: '清空',
            sel: false
          }
        ],
        // 抛光
        polish: [
          {
            val: 'EX',
            sel: false
          },
          {
            val: 'VG',
            sel: false
          },
          {
            val: 'GD',
            sel: false
          },
          {
            val: 'FR',
            sel: false
          },
          {
            val: 'P',
            sel: false
          }
        ],
        // 对称
        sym: [
          {
            val: 'EX',
            sel: false
          },
          {
            val: 'VG',
            sel: false
          },
          {
            val: 'GD',
            sel: false
          },
          {
            val: 'FR',
            sel: false
          },
          {
            val: 'P',
            sel: false
          }
        ],
        // 荧光
        flour: [
          {
            val: 'N',
            sel: false
          },
          {
            val: 'F',
            sel: false
          },
          {
            val: 'M',
            sel: false
          },
          {
            val: 'ST',
            sel: false
          },
          {
            val: 'VST',
            sel: false
          }
        ],
        // 证书
        cert: [
          {
            val: 'GIA',
            sel: false
          },
          {
            val: 'HRD',
            sel: false
          },
          {
            val: 'IGI',
            sel: false
          }
        ],
        // 无奶咖绿、肉眼净、有图/视频
        radioItem: [
          {
            val: 'IsMilkyOrBrownessOrGreen',
            text: '无奶咖绿',
            sel: false
          },
          {
            val: 'eyeClean',
            text: '肉眼干净',
            sel: false
          },
          // {
          //   val: 'havePictureOrVideo',
          //   text: '有图/有视频',
          //   sel: false
          // },
          {
            val: 'HavePictrue',
            text: '有图',
            sel: false
          },
          {
            val: 'HaveVidio',
            text: '有视频',
            sel: false
          }
        ],
        // 地区
        locations: [
          {
            val: '国内',
            text: '货在国内',
            sel: false
          },
          {
            val: '香港',
            text: '货在香港',
            sel: false
          },
          {
            val: '国外',
            text: '货在国外',
            sel: false
          },
        ],
        // 表格
        tableData: [],
        // 切工显示
        cutShow: true,
        // 重量根据页面来，ref直接取的值
        // diaSizeMin: 0.30,
        // diaSizeMax: 0.39,
        // diaval: '0.30-0.39',
        diaSizeMin: '',
        diaSizeMax: '',
        diaval: '',
        // diaSize: ['不限','0.30-0.39', '0.40-0.49', '0.50-0.69', '0.70-0.89', '0.90-0.99', '1.00-1.49', '1.50-1.99', '2.00-2.99', '3.00-3.99', '4.00-4.99', '5.00-5.99', '10.00-10.99'],
        diaSize: ['不限','0.00-0.29','0.30-0.39','0.40-0.49','0.50-0.59','0.60-0.69','0.70-0.79','0.80-0.89','0.90-0.99','1.00-1.49','1.50-1.99','2.00-2.99','3.00-3.99','4.00-4.99','5.00-5.99','10.0-10.99'],
        // 价钱
        diaPriceMin: 0,
        diaPriceMax: 100000000,
        // 证书号
        certNo: '',
        GIAShow: true,
        // 商铺id
        relationId: '',
        // 总条数
        total: 0,
        // 页码
        currentPage: 1,
        // 条数
        pageSize: 20,
        // 条数选择
        pageArr: [10, 20, 50, 100],
        // 搜出的条数
        totalItems: 0,
        // 表格加载中
        listloading: false,
        // 密码功能
        searchShow: false,
        searchPass: '',
        sessionPass: true,
        OrderFlag: 1,
        isAsc: true,
        // 产地
        productionPlace:[],
      }
    },
    mounted () {
      if (!this.$store.state.shop) {
        // this.$open()
      }
    },
    created () {
      if (this.$route.query.link) {
        this.$emit('showheader', false)
        this.$emit('showfooter', false)
      }
      this.GetListAsync()
    },
    filters: {
      getComeTime(val) {
        if(val == '') {
          return '待查'
        }

        let obj = {
          '美国': '国外',
          '比利时': '国外',
          '以色列': '国外',
          '迪拜': '国外',
          '香港': '香港',
          '印度': '国外',
          '上海': '上海',
          '孟买': '国外',
          '深圳': '深圳',
          '中国': '国内',
          '在途': '在途'
        }
        return obj[val]
      }
    },
    computed: {
      shopInit: function () {
        let shop = this.$store.state.shop
        let relationId = ''
        if (shop && this.$store.state.SequenceNo) {
          relationId = shop.relationId
        }
        return relationId
      }
    },
    watch: {
      shopInit: {
        handler (newValue, oldValue) {
          if (this.relationId != '' || newValue == '') return
          this.relationId = newValue
          if (newValue != '') {
            let shop = this.$store.state.shop
            this.$getif(shop.vipType)
            this.getInit()
          }
        },
        immediate: true,
      },
      diaval: {
        handler (newValue, oldValue) {
          if (newValue == '') return
          if (newValue === '不限') {
            this.diaSizeMin = ''
            this.diaSizeMax = ''
            return
          }
          console.log(this.diaval)
          let arr = newValue.split('-')
          console.log(arr)
          this.diaSizeMin = arr[0]
          this.diaSizeMax = arr[1]
        }
      },
      searchShow: {
        handler (newValue, oldValue) {
          if (newValue) {
            console.log(newValue)
          }else{
            console.log(newValue)
          }
        }
      }
    },
    beforeRouteLeave (to, from, next) {
      from.meta.keepAlive = false
      next()
    },
    methods: {
      GetListAsync(){
        Diamond.GetListAsync().then(res=>{
          if(res.status==200){
            res.result.map((item,index)=>{
              this.productionPlace.push({val:item.name,id:item.id,sel:false})
            })
          }
        })
      },
      loolSearch() {
        // let params = {
        //   pwd: this.searchPass
        // }
        let params = `pwd=${this.searchPass}`

        Diamond.checkPwd(params).then(res => {
          console.log(res)
          if (res.status == 200) {
            this.searchShow = false
            if (this.sessionPass) {
              window.localStorage.setItem('searchPass', this.searchPass)
            }else{
              window.localStorage.setItem('searchPass', '')
            }
            this.getSearch()
          }
        }).catch(err => {
          this.$message({ type: 'error', message: err.data.error});
          this.searchShow = true
        })

        // this.searchShow = false
        // this.getInit()
      },
      changGIA (event) {
        console.log(event.currentTarget.value)
        this.certNo = event.currentTarget.value
      },
      // 初始化
      getInit () {
        Diamond.init().then(res => {
          if (res.status == 200) {
            let shape = []
            let data = res.result.shapeList
            data.map((item, index) => {
              let val = item
              switch (item) {
                case '圆形':
                  val = 'ROUND'
                  break
                case '心形':
                  val = 'HEART'
                  break
                case '垫形':
                  val = 'CUSHION'
                  break
                case '公主方':
                  val = 'PRINCESS'
                  break
                case '梨形':
                  val = 'PEAR'
                  break
                case '椭圆':
                  val = 'OVAL'
                  break
                case '祖母绿':
                  val = 'EMERALD'
                  break
                case '三角形':
                  val = 'TRIANGLE'
                  break
                case '雷蒂恩':
                  val = 'RADIANT'
                  break
                case '马眼':
                  val = 'MARQUISE'
                  break
                case '阿斯切':
                  val = 'SQUARE'
                  break
                case '其他':
                  val = 'OTHER'
                  break
              }
              let items = {
                'img': `https://taotuo.oss-cn-hangzhou.aliyuncs.com/uniapp/Diamond/shape/pc/${val}.png`,
                'selimg': `https://taotuo.oss-cn-hangzhou.aliyuncs.com/uniapp/Diamond/shape/pc/W_${val}.png`,
                'val': val,
                'show': item,
                'sel': false
              }
              // 'sel': val == 'ROUND' ? true : false
              shape.push(items)
            })
            this.shape = shape
            if(res.result.isNeedPassword) {
              if (res.result.needPasswordRang[0].isOpen) {
                let searchPass = window.localStorage.getItem('searchPass')
                if (searchPass != '' && searchPass) {
                  this.searchPass = searchPass
                  this.loolSearch()
                }else{
                  this.searchShow = true
                }
              }else{
                this.getSearch()
              }
            }else{
              this.getSearch()
            }

            // this.getSearch()
          }
        })
      },
      // 重量输入
      changectMin (e) {
        let val = e.currentTarget.value
        if(val == '') return
        if(!RegExp(/^[0-9][0-9]*([.][0-9]+)?$/).test(val)) return
        this.diaSizeMin = val
        let max = (parseInt(val*10)/10)+0.09
        this.diaSizeMax = Math.round(max*100)/100
        // this.rangeValues = [0.1, parseInt(this.max*100)/100]
      },
      changectMax (e) {
        let val = e.currentTarget.value
        if(val == '') return
        if(!RegExp(/^[0-9][0-9]*([.][0-9]+)?$/).test(val)) return
        this.diaSizeMax = val
        // this.rangeValues = [0.1, this.max]
      },
      // 选择
      selItem (str, val) {
        console.log(str, val)
        switch (str) {
          case 'shape':
            // 加减状态
            this.shape.map((item, index) => {
              if (item.val == val) {
                item.sel ? this.$set(item, 'sel', false) : this.$set(item, 'sel', true)
              }
            })
            // 判断是否显示切工，和快捷健
            let check = this.shape.map(item => {
              return item.sel ? item.val : false
            }).filter(Boolean)
            if (check.length == 0) {
              this.cutShow = true
            } else if (check.length == 1) {
              if (check[0] == 'ROUND') {
                this.cutShow = true
              } else {
                this.cutShow = false
              }
            } else {
              this.cutShow = false
            }
            // 修改显示的数据
            if (!this.cutShow) {
              this.cut.map((item, index) => {
                this.$set(item, 'sel', false)
              })
              this.hotkey = [
                {
                  val: '2EX',
                  sel: false
                },
                {
                  val: '2VG',
                  sel: false
                },
                {
                  val: '2GD',
                  sel: false
                },
                {
                  val: '清空',
                  sel: false
                }
              ]
            } else {
              this.hotkey = [
                {
                  val: '3EX',
                  sel: false
                },
                {
                  val: '3VG',
                  sel: false
                },
                {
                  val: '3GD',
                  sel: false
                },
                {
                  val: '清空',
                  sel: false
                }
              ]
            }
            this.cutex('EX')
            this.cutex('VG')
            this.cutex('G')
            console.log(this.cutShow)
            break
          case 'color':
            this.color.map((item, index) => {
              if (item.val == val) {
                item.sel ? this.$set(item, 'sel', false) : this.$set(item, 'sel', true)
              }
            })
            break
          case 'clarity':
            this.clarity.map((item, index) => {
              if (item.val == val) {
                item.sel ? this.$set(item, 'sel', false) : this.$set(item, 'sel', true)
              }
            })
            break
          case 'cut':
            this.cut.map((item, index) => {
              if (item.val == val) {
                item.sel ? this.$set(item, 'sel', false) : this.$set(item, 'sel', true)
              }
            })
            break
          case 'polish':
            this.polish.map((item, index) => {
              if (item.val == val) {
                item.sel ? this.$set(item, 'sel', false) : this.$set(item, 'sel', true)
              }
            })
            break
          case 'sym':
            this.sym.map((item, index) => {
              if (item.val == val) {
                item.sel ? this.$set(item, 'sel', false) : this.$set(item, 'sel', true)
              }
            })
            break
          case 'flour':
            this.flour.map((item, index) => {
              if (item.val == val) {
                item.sel ? this.$set(item, 'sel', false) : this.$set(item, 'sel', true)
              }
            })
            break
          case 'cert':
            this.cert.map((item, index) => {
              if (item.val == val) {
                item.sel ? this.$set(item, 'sel', false) : this.$set(item, 'sel', true)
              }
            })
            break
          case 'Place':
            this.productionPlace.map((item, index) => {
              if (item.val == val) {
                item.sel ? this.$set(item, 'sel', false) : this.$set(item, 'sel', true)
              }else{
                this.$set(item, 'sel', false)
              }
            })
            break
          case 'radioItem':
            this.radioItem.map((item, index) => {
              if (item.val == val) {
                item.sel ? this.$set(item, 'sel', false) : this.$set(item, 'sel', true)
              }
            })
            break
          case 'locations':
            this.locations.map((item, index) => {
              if (item.val == val) {
                item.sel ? this.$set(item, 'sel', false) : this.$set(item, 'sel', true)
              }
            })
            break
        }
      },
      // 热键选择
      hotCheck (str, val) {
        this.cut.map((item, index) => {
          this.$set(item, 'sel', false)
        })
        this.polish.map((item, index) => {
          this.$set(item, 'sel', false)
        })
        this.sym.map((item, index) => {
          this.$set(item, 'sel', false)
        })
        this.hotkey.map((item, index) => {
          this.$set(item, 'sel', false)
        })
        if (val === '清空') return
        this.hotkey.map((item, index) => {
          if (item.val == val) {
            item.sel ? this.$set(item, 'sel', false) : this.$set(item, 'sel', true)
          }
        })
        switch (val) {
          case '3EX':
            this.hotex('EX')
            break
          case '3VG':
            this.hotex('EX')
            this.hotex('VG')
            break
          case '3GD':
            this.hotex('EX')
            this.hotex('VG')
            this.hotex('GD')
            break
          case '2EX':
            this.hotex('EX')
            break
          case '2VG':
            this.hotex('EX')
            this.hotex('VG')
            break
          case '2GD':
            this.hotex('EX')
            this.hotex('VG')
            this.hotex('GD')
            break
        }
      },
      async hotex (val) {
        console.log(val)
        // 切工
        if (this.cutShow) {
          await this.cut.map((item, index) => {
            if (item.val == val) {
              this.$set(item, 'sel', true)
            }
          })
        }
        // 抛光
        await this.polish.map((item, index) => {
          if (item.val == val) {
            this.$set(item, 'sel', true)
          }
        })
        // 对称
        await this.sym.map((item, index) => {
          if (item.val == val) {
            this.$set(item, 'sel', true)
          }
        })
      },
      // 消除
      async cutex (val) {
        // 切工
        if (this.cutShow) {
          await this.cut.map((item, index) => {
            this.$set(item, 'sel', false)
          })
        }
        // 抛光
        await this.polish.map((item, index) => {
          this.$set(item, 'sel', false)
        })
        // 对称
        await this.sym.map((item, index) => {
          this.$set(item, 'sel', false)
        })
      },
      // 分页
      changePage (val) {
        this.currentPage = val
        this.getSearch()
      },
      changePages(val) {
        this.currentPage = val
        this.goAnchor('#searchId')
        this.getSearch()
      },
      handleSizeChange(val) {
        console.log(val)
        this.pageSize = val
        this.getSearch()
      },
      handleSizeChanges(val) {
        console.log(val)
        this.pageSize = val
        this.goAnchor('#searchId')
        this.getSearch()
      },
      getSear () {
        this.currentPage = 1
        this.getSearch()
      },
      // VIP钻石小鸟高端定制   18888921203
      shortcutSearch(){
        this.selItem('radioItem', 'IsMilkyOrBrownessOrGreen')
        this.selItem('radioItem', 'eyeClean')
        this.hotCheck('hotkey', '3EX')
      },
      sortChange(sort) {
        if (sort.prop == "diaSize") {
          if (sort.order == "ascending") {
            this.OrderFlag = 5;
          } else if (sort.order == "descending") {
            this.OrderFlag = 6;
          }
        }
        if (sort.prop == "price") {
          if (sort.order == "ascending") {
            this.OrderFlag = 1;
          } else if (sort.order == "descending") {
            this.OrderFlag = 2;
          }
        }
        this.getSearch()
      },
      // 搜索
      getSearch () {
        this.listloading = false
        if (this.diaSizeMax != '' && this.diaSizeMax == 0) {
          this.total = 0
          this.tableData = []
          return
        }

        let params = {}
        // 形状 颜色 净度 切工 抛光 对称 荧光 证书 价钱最小-价钱最大 克重小-克重大 奶咖 肉眼净 图/视频 货地址 专业选钻 商户id 页码 条数
        params.shape = this.shape.map(item => {
          return item.sel ? item.val : false
        }).filter(Boolean).join(',')
        params.color = this.color.map(item => {
          return item.sel ? item.val : false
        }).filter(Boolean).join(',')
        params.clarity = this.clarity.map(item => {
          return item.sel ? item.val : false
        }).filter(Boolean).join(',')
        params.cut = this.cut.map(item => {
          return item.sel ? item.val : false
        }).filter(Boolean).join(',')
        params.polish = this.polish.map(item => {
          return item.sel ? item.val : false
        }).filter(Boolean).join(',')
        params.sym = this.sym.map(item => {
          return item.sel ? item.val : false
        }).filter(Boolean).join(',')
        params.flour = this.flour.map(item => {
          return item.sel ? item.val : false
        }).filter(Boolean).join(',')
        params.cert = this.cert.map(item => {
          return item.sel ? item.val : false
        }).filter(Boolean).join(',')
        params.diaPriceMin = 0
        let a = this.productionPlace.some(item=>{
          return item.sel==true
        })
        console.log(a);
        if(a){
          this.productionPlace.map(item=>{
            if(item.sel){
              params.fireCertId = item.id
            }
          })
        }else{
          params.fireCertId = 0
        }
        params.diaPriceMax = 1000000000
        if(this.diaSizeMin == '' && this.diaSizeMax == '') {
          params.diaSizeMin = Number(this.diaSizeMin)
          params.diaSizeMax = 100
        }else{
          params.diaSizeMin = Number(this.diaSizeMin)
          params.diaSizeMax = Number(this.diaSizeMax)
        }
        this.radioItem.map((item, index) => {
          if (item.val == 'IsMilkyOrBrownessOrGreen') {
            item.sel ? params.IsMilkyOrBrownessOrGreen = true : params.IsMilkyOrBrownessOrGreen = null
          }
          if (item.val == 'eyeClean') {
            item.sel ? params.eyeClean = 1 : params.eyeClean = null
          }
          // 图、视频（结合）
          // if (item.val == 'havePictureOrVideo') {
          //   item.sel ? params.havePictureOrVideo = true : params.havePictureOrVideo = null
          // }
          //图，视频（分开）
          if (item.val == 'HavePictrue') {
            item.sel ? params.HavePictrue = true : params.HavePictrue = null
          }
          if (item.val == 'HaveVidio') {
            item.sel ? params.HaveVidio = true : params.HaveVidio = null
          }
        })

        if (this.OrderFlag != '') {
          params.OrderFlag = this.OrderFlag
        }
        params.location = this.locations.map(item => {
          return item.sel ? item.val : false
        }).filter(Boolean).join(',')
        params.searchType = 2
        params.relationId = this.relationId
        params.pageIndex = this.currentPage
        params.pageSize = this.pageSize
        let certNo = this.certNo
        if (certNo != '') {
          if (certNo.length >= 8 && certNo.length <= 12) {

          } else {
            // alert('证书号格式错误!!!')
            this.$message({ type: 'error', message: '证书号格式错误'});
            return
          }
          params = {}
          params.certNo = certNo
          params.searchType = 2
          params.relationId = this.relationId
        }

        Diamond.list(params).then(res => {
          console.log(res)
          if (res.status == 200) {
            this.listloading = false
            let data = res.result

            // if(data.items[0].certNo == '' && data.items[1].certNo == '') {
            //   this.GIAShow = false
            // }else{
            //   this.GIAShow = true
            // }
            this.total = data.totalItems
            this.tableData = data.items
          }
        })
      },
      // 重置
      clearSearch () {
        this.shape.map((item, index) => {
          this.$set(item, 'sel', false)
          // item.val == 'ROUND' ? this.$set(item, 'sel', true) : this.$set(item, 'sel', false)
        })
        this.color.map((item, index) => {
          this.$set(item, 'sel', false)
        })
        this.clarity.map((item, index) => {
          this.$set(item, 'sel', false)
        })
        this.cut.map((item, index) => {
          this.$set(item, 'sel', false)
        })
        this.hotkey.map((item, index) => {
          this.$set(item, 'sel', false)
        })
        this.polish.map((item, index) => {
          this.$set(item, 'sel', false)
        })
        this.sym.map((item, index) => {
          this.$set(item, 'sel', false)
        })
        this.flour.map((item, index) => {
          this.$set(item, 'sel', false)
        })
        this.radioItem.map((item, index) => {
          this.$set(item, 'sel', false)
          // item.val == 'havePictureOrVideo' ? this.$set(item, 'sel', false) : this.$set(item, 'sel', true)
        })
        this.locations.map(item => {
          this.$set(item, 'sel', false)
        })
        this.cert.map(item => {
          this.$set(item, 'sel', false)
          // item.val == 'GIA' ? this.$set(item, 'sel', true) : this.$set(item, 'sel', false)
        })
        this.productionPlace.map(item => {
          this.$set(item, 'sel', false)
          // item.val == 'GIA' ? this.$set(item, 'sel', true) : this.$set(item, 'sel', false)
        })
        this.currentPage = 1
        this.pageSize = 20
        // this.diaSizeMin = 0.30
        // this.diaSizeMax = 0.39
        // this.diaval = '0.30-0.39'
        this.diaSizeMin = ''
        this.diaSizeMax = ''
        this.diaval = ''
        this.diaPriceMin = 10000
        this.diaPriceMax = 220000
        this.certNo = ''
        this.OrderFlag = 1
        // console.log(this.$refs.tableList)
        // this.$refs.tableList.clearSort()
        this.getSearch()
      },
      // 列表选中
      listsle () {
      },
      // 点击去往详情
      goDetails (row, column, event) {
        console.log('row', row)
        if (this.$route.query.link) {
          this.$router.push({
            path: '/diamondDetails',
            query: {
              // fireCert:JSON.stringify(row.fireCert),
              GoodsBarCode: row.goodsBarCode,
              relationId: this.relationId,
              link: 1
            }
          })
        } else {
          window.open(`/diamondDetails?GoodsBarCode=${row.goodsBarCode}&relationId=${this.relationId}`)
        }
      },
      goAnchor (selector) {
        var anchor = document.querySelector(selector)
        //   没有动画
        //   document.documentElement.scrollTop = anchor.offsetTop
        //   滚动到顶部
          document.documentElement.scrollTop = 433
        //   有动画
        // this.scrollTo(anchor.offsetTop, 300)
      },
      scrollTo (y, duration) {
        /* y:目标纵坐标,duration:时间(毫秒) */
        // var scrollTop = document.body.scrollTop /* 页面当前滚动距离 */
        var scrollTop = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset
        console.log(scrollTop)
        var distance = y - scrollTop /* 结果大于0,说明目标在下方,小于0,说明目标在上方 */
        var scrollCount = duration / 10 /* 10毫秒滚动一次,计算滚动次数 */
        var everyDistance = parseInt(
          distance / scrollCount
        ) /* 滚动距离除以滚动次数计算每次滚动距离 */

        for (
          var index = 1;
          index <= scrollCount;
          index++ /* 循环设置scrollBy事件,在duration之内,完成滚动效果 */
        ) {
          setTimeout(function () {
            window.scrollBy(0, everyDistance)
          }, 10 * index)
        }
        let deviation = y - scrollCount * everyDistance
        window.scrollBy(0, deviation)
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep .el-table .sort-caret.ascending{
    border-bottom-color: #333333;
  }
  ::v-deep .el-table .ascending .sort-caret.ascending{
    border-bottom-color: #F5B5A9;
  }
  ::v-deep .el-table .descending .sort-caret.ascending{
    border-bottom-color: #C0C4CC;
  }

  ::v-deep .el-table .sort-caret.descending{
    border-top-color: #333333;
  }
  ::v-deep .el-table .descending .sort-caret.descending{
    border-top-color: #F5B5A9;
  }
  ::v-deep .el-table .ascending .sort-caret.descending{
    border-top-color: #C0C4CC;
  }

  .search-pass{
    width: 100%;
    height: 70vh;

    .search-main{
      width: 100%;
      height: 100%;
      background: rgba(255,255,255);
      background-image: url('../../assets/search-bgs.png');
      background-repeat: no-repeat;
      background-size: 80%;
      background-position: center center;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;

      .search-main-div{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        text-align: center;

        input{
          width: 400px;
          height: 50px;
          background: #FFFFFF;
          border: 1px solid #B2B2B2;
          line-height: 50px;
          font-size: 16px;
          color: #999999;
          text-align: center;
        }

        .search-main-sel{
          margin-top: 20px;

          ::v-deep .el-checkbox__input.is-checked+.el-checkbox__label{
            color: #F5B5A9 !important;
          }
        }

        .btn-s{
          width: 400px;
          height: 50px;
          background: #FF8178;
          font-size: 16px;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 50px;
          margin-top: 30px;
          cursor: pointer;
        }
      }
    }
  }

  .main {
    width: 1200px;
    padding: 40px 0 80px;
    margin: 0 auto;
    /*搜索*/
    .search {
      width: 100%;
      border-bottom: 1px solid #E5E5E5;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: nowrap;
      font-size: 14px;
      color: #333333;
      user-select: none;

      .select {
        width: 56%;
        border-right: 1px solid #E5E5E5;

        .items {
          width: 100%;
          min-height: 30px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: nowrap;

          .item {
            width: 58px;
            height: 30px;
            border-right: 2px solid #FFFFFF;
            border-bottom: 2px solid #FFFFFF;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &:hover {
              color: #F5B5A9;
            }

            &.title {
              min-width: 60px;
              text-align: center;
              font-size: 14px;
              cursor: default;
              font-weight: bold;
              color: #000000;
              margin-right: 10px;

              &:hover {
                color: #333333;
              }
            }

            &.sel {
              color: #FFFFFF;
              background: #F5B5A9;
              /*zoomIn*/
              /*animation: zoomIn .3s;*/
              /*animation-direction: alternate;*/
            }
            &.sell {
              color: #FFFFFF;
              background: #F5B5A9;
              /*zoomIn*/
              /*animation: zoomIn .3s;*/
              /*animation-direction: alternate;*/
            }
          }

          &.shape {
            height: 72px;
            color: #333333;

            .item {
              height: 100%;
              /*padding-top: 9px;*/
              /*padding-bottom: 10px;*/
              flex-wrap: wrap;
              align-content: center;

              img {
                /*width: 35px;*/
                height: 35px;
                margin-bottom: 3px;
              }

              .text {
                width: 100%;
                font-size: 13px;
              }
            }

          }
        }
      }

      .check {
        padding: 10px 30px;

        .items {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: nowrap;
          margin-bottom: 20px;

          .input-main {
            width: 205px;
            height: 35px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: nowrap;

            .dian {
              min-width: 23px;
              font-size: 14px;
            }

            input {
              width: 95px;
              height: 35px;
              border: 1px solid #C0C4CC;
              /*border-radius: 4px;*/
              outline: none;
              text-align: center;
              color: #333333;
            }
          }

          &.GIA {
            .input-main {
              input {
                width: 100%;
                text-align: left;
                padding-left: 23px;
              }
            }

            .el-select {
              width: 85px;
              height: 35px;
            }
          }

          .i-text {
            margin: 0 15px;
            color: #EE8976;
          }

          .el-select {
            width: 120px;
            height: 35px;
            margin-left: 15px;
          }

          ::v-deep .el-input__inner {
            height: 35px;
            padding-left: 10px !important;
            border-color: #C0C4CC;
            border-radius: 0;
          }

          ::v-deep .el-input__icon {
            line-height: 35px !important;
          }

          .label {
            height: 35px;
            /*border-radius: 4px;*/
            padding: 7px;
            border: 1px solid #C0C4CC;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 14px;
            color: #333333;
            cursor: pointer;
            margin-right: 15px;
            min-width: 80px;

            &:last-child{
              margin: 0;
            }

            .checked {
              width: 16px;
              height: 16px;
              border: 1px solid #C0C4CC;
              color: #000;
              border-radius: 2px;
              position: relative;
              margin-right: 8px;

              &:before {
                box-sizing: content-box;
                content: "";
                border: 1px solid #fff;
                border-left: 0;
                border-top: 0;
                height: 7px;
                left: 5px;
                position: absolute;
                top: 2px;
                width: 3px;
                transition: transform .15s ease-in .05s;
                transform-origin: center;
                transform: rotate(45deg) scaleY(1);
              }
            }

            &.sel {
              border: 1px solid #F5B5A9;

              .checked {
                border: 1px solid #F5B5A9;
                background: #F5B5A9;
              }
            }
          }

          .search-btn {
            width: 95px;
            height: 35px;
            background: #F5B5A9;
            /*border-radius: 4px;*/
            font-size: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #FFFFFF;
            margin-right: 15px;
            cursor: pointer;
          }
        }
      }
    }

    /*列表*/
    .total-main {
      padding: 24px 0 26px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .total {
        font-size: 14px;
        color: #666666;
      }
    }

    /*选中，移入移出css*/
    ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background: #F5B5A9 !important;
      border-color: #F5B5A9 !important;
    }

    ::v-deep .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background: #F5B5A9 !important;
      border-color: #F5B5A9 !important;
    }

    ::v-deep .el-checkbox__inner:hover {
      border-color: #F5B5A9 !important;
    }

    ::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
      /*background: #FFF9F7;*/
      background: #FEEFEA;
      cursor: pointer;
    }

    .page.last {
      margin-top: 30px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .page{
      ::v-deep .el-select .el-input.is-focus .el-input__inner{
        border-color: #F5B5A9!important;
      }

      ::v-deep .el-pagination__sizes .el-input .el-input__inner:hover{
        border-color: #F5B5A9!important;
      }


    }

    /*表格穿透*/
    ::v-deep .el-table {
      .has-gutter {
        width: 100%;
      }

      th {
        padding: 0;
        height: 48px;
        color: #333333;
        font-weight: 500;

        .cell {
          height: 48px;
          line-height: 48px;
        }
      }

      td {
        border-top: 10px solid #FFFFFF;
        border-bottom: 1px solid #FAFAFA !important;
        background: #FAFAFA;
        padding: 0;
        height: 70px;
        color: #666666;
      }

      td .cell {
        position: relative;

        &:before {
          content: '';
          position: absolute;
          height: 10px;
          width: 1px;
          right: 0;
          top: calc(100% - 15px);
          background: #CCCCCC;
        }
      }

      tr td:last-child .cell {
        &:before {
          content: '';
          position: absolute;
          height: 10px;
          width: 0;
          right: 0;
          top: calc(100% - 15px);
          background: #CCCCCC;
        }
      }
    }

    ::v-deep .el-table th {
      background: #F5F5F5;
      text-align: center !important;
    }

    ::v-deep .el-table td {
      background: #FAFAFA;
      text-align: center !important;
    }

    ::v-deep .el-table th.is-leaf {
      border-color: #FFFFFF !important;
    }
  }

  ::v-deep .el-select-dropdown__item.selected {
    color: #F5B5A9 !important;
  }

  /*表格自定义*/
  .list-img {
    width: 100%;
    height: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;

    img {
      /*width: 30px;*/
      height: 30px;
    }
  }

  ::v-deep .el-table--border::after, .el-table--group::after, .el-table::before {
    background: transparent;
  }

  .list-price {
    color: #FF8178;
    width: 100%;
    text-align: center;
  }

  @import '@/assets/css/main.scss';

  @keyframes zoomIn {
    from {
      opacity: 0;
      -webkit-transform: scale3d(0.3, 0.3, 0.3);
      transform: scale3d(0.3, 0.3, 0.3);
    }

    50% {
      opacity: 1;
    }
  }
</style>
<style>
  .el-select-dropdown__item.selected{
    color: #F5B5A9!important;
  }

  .el-input--mini .el-input__inner{
    height: 30px;
    line-height: 30px;
  }

  .el-select .el-input__inner:focus{
    border-color: #F5B5A9!important;
  }
</style>
